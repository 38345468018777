'use client';
import {
  Block,
  BlockNoteEditor,
  BlockNoteSchema,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
  defaultStyleSpecs,
  filterSuggestionItems,
} from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';
import {
  SuggestionMenuController,
  useCreateBlockNote,
  DefaultReactSuggestionItem,
  getDefaultReactSlashMenuItems,
  BlockTypeSelect,
  FormattingToolbarController,
  FormattingToolbar,
  BasicTextStyleButton,
  CreateLinkButton,
  SideMenuController,
  SideMenu,
  DragHandleMenu,
  RemoveBlockItem,
} from '@blocknote/react';
import '@blocknote/mantine/style.css';
import { Typography } from '@ds';
import clsx from 'clsx';

// List containing all Headings and Basic blocks Slash Menu Items
const getCustomSlashMenuItems = (
  editor: BlockNoteEditor
): DefaultReactSuggestionItem[] => {
  // Get default items first
  const defaultItems = getDefaultReactSlashMenuItems(editor);
  // Filter to only keep Headings and Basic blocks
  return defaultItems.filter(
    (slashMenuItem) =>
      slashMenuItem.group === 'Headings' ||
      slashMenuItem.group === 'Basic blocks'
  );
};

export const BlockNote = function BlockNote({
  error,
  helperText,
  initialContent,
  label,
  maxHeightClass,
  onChange,
  required,
  showHeadings = true,
}: {
  error?: boolean;
  helperText?: string;
  initialContent: Block[] | undefined;
  label?: string;
  maxHeightClass?: string;
  onChange: (changes: Block[]) => void;
  required?: boolean;
  showHeadings?: boolean;
}) {
  const schema = BlockNoteSchema.create({
    blockSpecs: showHeadings
      ? defaultBlockSpecs
      : Object.fromEntries(
          Object.entries(defaultBlockSpecs).filter(([key]) => key !== 'heading')
        ),
    inlineContentSpecs: defaultInlineContentSpecs,
    styleSpecs: defaultStyleSpecs,
  });

  const editorOptions = {
    schema,
  } as const;

  if (initialContent?.length) {
    Object.assign(editorOptions, { initialContent });
  }

  const editor = useCreateBlockNote(editorOptions);

  const handleChange = async () => {
    onChange(editor.document as Block[]);
  };

  return (
    <div className="space-y-1.5">
      {!!label && (
        <label className="block">
          <Typography
            className="text-gray-700"
            component="span"
            variant="text-label-sm"
          >
            {label}
            {required ? ' *' : null}
          </Typography>
        </label>
      )}
      <div className="shadow-xs focus:border-amplify-green-500 focus:ring-fresh-neon-100 relative overflow-hidden rounded-lg border border-gray-300 py-2  disabled:border-gray-200">
        <div className={clsx(' overflow-y-auto', maxHeightClass ?? 'h-full')}>
          <BlockNoteView
            data-custom-blocknote-css
            editor={editor}
            formattingToolbar={false}
            sideMenu={false}
            slashMenu={false}
            theme={'light'}
            onChange={handleChange}
          >
            <SuggestionMenuController
              // Replaces the default Slash Menu items with our custom ones.
              getItems={async (query) =>
                // @ts-expect-error - Not sure how to fix the type error here
                filterSuggestionItems(getCustomSlashMenuItems(editor), query)
              }
              triggerCharacter={'/'}
            />
            <SideMenuController
              sideMenu={(props) => (
                <SideMenu
                  {...props}
                  dragHandleMenu={(props) => (
                    <DragHandleMenu {...props}>
                      <RemoveBlockItem {...props}>Delete</RemoveBlockItem>
                    </DragHandleMenu>
                  )}
                />
              )}
            />

            <FormattingToolbarController
              formattingToolbar={() => (
                <FormattingToolbar>
                  <BlockTypeSelect key={'blockTypeSelect'} />

                  <BasicTextStyleButton
                    key={'boldStyleButton'}
                    basicTextStyle={'bold'}
                  />
                  <BasicTextStyleButton
                    key={'italicStyleButton'}
                    basicTextStyle={'italic'}
                  />
                  <BasicTextStyleButton
                    key={'underlineStyleButton'}
                    basicTextStyle={'underline'}
                  />
                  <BasicTextStyleButton
                    key={'strikeStyleButton'}
                    basicTextStyle={'strike'}
                  />

                  <CreateLinkButton key={'createLinkButton'} />
                </FormattingToolbar>
              )}
            />
          </BlockNoteView>
        </div>
      </div>
      {!!helperText && (
        <Typography
          className={error ? 'text-red-500' : 'text-gray-600'}
          variant="text-body-sm"
        >
          {helperText}
        </Typography>
      )}
    </div>
  );
};
