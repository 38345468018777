import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Typography } from '@/components/ui/typography';

type OptionValue = string | number | undefined;

type Option<SelectType extends OptionValue> = {
  disabled?: boolean;
  label: string;
  value: SelectType;
};

type SelectProps<SelectType extends OptionValue> = {
  className?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  onChange: (value: SelectType) => void;
  options: Option<SelectType>[];
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg';
  value: SelectType;
};

export const Select = <SelectType extends OptionValue>({
  className,
  disabled,
  error,
  label,
  onChange,
  options,
  placeholder,
  size = 'md',
  value,
}: SelectProps<SelectType>) => {
  const sizeClasses = {
    lg: {
      button: 'py-3 pr-12 pl-4 text-lg',
      options: 'py-2 text-lg',
    },
    md: {
      button: 'py-2 pr-10 pl-4 text-base',
      options: 'py-1.5 text-base',
    },
    sm: {
      button: 'py-[7px] pr-8 pl-3 text-sm',
      options: 'py-1 text-sm',
    },
  };

  return (
    <Listbox disabled={disabled} value={value} onChange={onChange}>
      {({ open }) => (
        <div>
          {label && (
            <Listbox.Label className="block">
              <Typography component="span" variant="text-button-sm">
                {label}
              </Typography>
            </Listbox.Label>
          )}

          <div className={clsx({ 'mt-1': !!label, relative: true })}>
            <Listbox.Button
              className={clsx(
                'relative w-full cursor-default rounded-md border border-secondary-grey-dark bg-white font-normal text-text-main shadow focus:border-secondary-grey-dark focus:outline-none',
                error && 'border-red-500',
                sizeClasses[size].button,
                className
              )}
            >
              {options.find((option) => option.value === value)?.label ? (
                <span className="block truncate text-left">
                  {options.find((option) => option.value === value)?.label}
                </span>
              ) : (
                <span className="block truncate text-text-disabled">
                  {placeholder ?? ''}
                </span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  aria-hidden="true"
                  className={clsx(
                    'h-5 w-5 text-gray-400',
                    error && 'text-red-500'
                  )}
                />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <Listbox.Options
                className={clsx(
                  'absolute z-20 mt-1 max-h-60 w-full cursor-pointer overflow-auto rounded-md bg-white font-normal shadow-lg ring-1 ring-black/5 focus:outline-none',
                  sizeClasses[size].options
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active, disabled, selected }) =>
                      clsx(
                        'relative cursor-default select-none py-2 pl-3 pr-9 text-text-main',
                        active && 'bg-gray-200',
                        selected && 'bg-gray-100',
                        disabled && 'cursor-not-allowed opacity-50'
                      )
                    }
                    disabled={option.disabled}
                    value={option.value}
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? '' : 'text-primary-green-dark',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon aria-hidden="true" className="size-5" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {error && (
            <Typography
              className="text-red-500"
              component="span"
              variant="text-label-sm"
            >
              {error}
            </Typography>
          )}
        </div>
      )}
    </Listbox>
  );
};
