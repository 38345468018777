export const ClockSlashIcon: React.ComponentType<
  React.SVGProps<SVGSVGElement>
> = (props) => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M2.53033 1.46967C2.23744 1.17678 1.76256 1.17678 1.46967 1.46967C1.17678 1.76256 1.17678 2.23744 1.46967 2.53033L17.4697 18.5303C17.7626 18.8232 18.2374 18.8232 18.5303 18.5303C18.8232 18.2374 18.8232 17.7626 18.5303 17.4697L16.3399 15.2793C17.532 13.849 18.25 12.0076 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75C7.9922 1.75 6.15095 2.46802 4.72073 3.66007L2.53033 1.46967ZM5.7867 4.72604L9.25 8.18934V5C9.25 4.58579 9.58579 4.25 10 4.25C10.4142 4.25 10.75 4.58579 10.75 5V9.25H13.75C14.1642 9.25 14.5 9.58579 14.5 10C14.5 10.4142 14.1642 10.75 13.75 10.75H11.8107L15.274 14.2133C16.1981 13.0582 16.75 11.5942 16.75 10C16.75 6.27208 13.7279 3.25 10 3.25C8.40591 3.25 6.94179 3.80185 5.7867 4.72604Z"
        fill="#111927"
        fill-rule="evenodd"
      />
      <path
        d="M3.81133 7.30028C3.97717 6.92071 3.80391 6.47857 3.42434 6.31274C3.04478 6.1469 2.60264 6.32016 2.4368 6.69972C1.99481 7.71133 1.75 8.82806 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C11.1719 18.25 12.2887 18.0052 13.3003 17.5632C13.6798 17.3974 13.8531 16.9552 13.6873 16.5757C13.5214 16.1961 13.0793 16.0228 12.6997 16.1887C11.8739 16.5495 10.9614 16.75 10 16.75C6.27208 16.75 3.25 13.7279 3.25 10C3.25 9.03863 3.45053 8.12606 3.81133 7.30028Z"
        fill="#111927"
      />
    </svg>
  );
};

export default ClockSlashIcon;
